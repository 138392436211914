import mixpanel from 'mixpanel-browser'

import { isLocalEnvironment, isTestEnvironment } from '@/environment.ts'
import Hotjar from '@hotjar/browser'

if (!isTestEnvironment) {
    Hotjar.init(5135022, 6)
}

const projectToken = isTestEnvironment
    ? '352d226ab918fde801ce6c5cb3b0e333' // Testing
    : 'bbb91f1604af9c3f3bf8a96c9663c472' // Production

mixpanel.init(projectToken, {
    api_host: 'https://mixpanel-tracking-proxy-7tducinnmq-uc.a.run.app', // TODO : <FILL> use a proxy from this project instead the botwhirl one
    debug: isLocalEnvironment,
})
