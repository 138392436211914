import { useMemo, useState } from 'react'

import { Timestamp } from 'firebase/firestore'

import { setDocument } from '@/firebase/DocumentsDAO'
import { Question } from '@monorepo/functions/src/types/feedback'

import { useCollection } from '../firebase/hooks/useCollection'

type Rating = 'NEGATIVE' | 'NEUTRAL' | 'POSITIVE'

type PromoterRating = {
    promoterId: string
    value: Rating
    createdAt: Timestamp
}

export type AssisstantQuestion = Question & {
    id: string // TODO Should id be directly in Question?
    ratedBy: string[]
    ratings: { [key in string]: PromoterRating }
}

type UseAssistantQuestionsResponse = {
    questions: AssisstantQuestion[]
    areQuestionsLoading: boolean
    isRatingLoading: boolean
    setPromoterRating: (question: AssisstantQuestion, rating: Rating) => Promise<void>
}

const setPromoterRatingInDB = async (questionId: string, ratings: AssisstantQuestion['ratings']) => {
    await setDocument({ collectionName: `/assistant_questions`, id: questionId, data: { ratings } })
}

export const useAssistantQuestions = ({ promoterId }: { promoterId: string }): UseAssistantQuestionsResponse => {
    const [isRatingLoading, setIsRatingLoading] = useState(false)
    const { results: questions, isLoading: areQuestionsLoading } = useCollection<AssisstantQuestion>({
        path: `/assistant_questions`,
    })

    const unratedQuestions = useMemo(
        () => questions.filter(q => q.ratings?.[promoterId] === undefined),
        [questions, promoterId],
    )

    const setPromoterRating = async (question: AssisstantQuestion, rating: Rating) => {
        try {
            const promoterRating: PromoterRating = { value: rating, createdAt: Timestamp.now(), promoterId }
            setIsRatingLoading(true)

            const ratings = question.ratings ?? {}
            ratings[promoterId] = promoterRating

            await setPromoterRatingInDB(question.id, ratings)
        } catch (err) {
            console.error('Some error happened while rating question ', question.id, err)
        } finally {
            setIsRatingLoading(false)
        }
    }

    return { questions: unratedQuestions, setPromoterRating, areQuestionsLoading, isRatingLoading }
}
