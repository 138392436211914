import { motion } from 'framer-motion'

import { useEventFeedbackAnswer } from '@/hooks/useEventFeedback'
import { BaseFormStepProps } from '@/types'
import { MultiSelectQuestion } from '@monorepo/functions/src/types/feedback'
import { CheckedState } from '@radix-ui/react-checkbox'

import { Button } from './ui/button'
import { Checkbox } from './ui/checkbox'
import { Label } from './ui/label'

const buttonVariants = {
    initial: { scale: 1, opacity: 0, x: -20 },
    pressed: { scale: 0.95 },
}

const FormButton = motion(Button)

export const MultiSelectFormStep = ({ question, onNextStep }: BaseFormStepProps<MultiSelectQuestion>) => {
    const { setAnswer, answer } = useEventFeedbackAnswer(question)

    const handleSelectOption = (checked: CheckedState, optionId: string) => {
        const currentAnswerValue = answer?.value ?? []

        const value = checked
            ? [...currentAnswerValue, optionId]
            : [...currentAnswerValue.filter(value => value !== optionId)]

        setAnswer({
            type: 'MULTI_SELECT',
            value,
            departments: question.departments,
            categories: question.categories,
        })
    }

    return (
        <>
            <h2 className='text-center text-xl font-bold'>
                {question.title}
                <span className='block font-light italic'>(Selecciona todas las que apliquen)</span>
            </h2>
            <div className='mt-6 flex w-fit flex-col justify-center justify-self-center'>
                {question.options.map(option => (
                    <div key={option.value} className='mb-2 flex items-center'>
                        <Checkbox
                            id={option.value}
                            checked={answer?.value.includes(option.value)}
                            onCheckedChange={checked => handleSelectOption(checked, option.value)}
                        />
                        <Label className='ml-2 text-lg' htmlFor={option.value}>
                            {option.label}
                        </Label>
                    </div>
                ))}
                {answer && (
                    <FormButton
                        type='button'
                        variants={buttonVariants}
                        whileTap='pressed'
                        size='default'
                        onClick={onNextStep}
                        initial='initial'
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className='absolute -bottom-14 right-0 w-fit self-end'
                    >
                        Siguiente
                    </FormButton>
                )}
            </div>
        </>
    )
}
