import { Timestamp } from 'firebase/firestore'

import { useDocument } from '@/firebase/hooks/useDocument'
import { useUser } from '@/hooks/auth'
import { useEventId } from '@/hooks/useEventId'
import {
    Answer,
    ExtractAnswerFromQuestion,
    PersonFeedback,
    PersonFeedbacks,
    Question,
} from '@monorepo/functions/src/types/feedback'

const useEventFeedback = () => {
    const eventId = useEventId()

    const { uid } = useUser()
    const {
        document: eventFeedback,
        setDocument: setEventFeedback,
        setDocumentSync: setEventFeedbackSync,
    } = useDocument<PersonFeedbacks>({
        collectionName: `/events/${eventId ?? 'demo'}/feedback`,
        id: uid,
        defaultValue: {
            id: uid,
            createdAt: Timestamp.now(),
            feedbacks: [],
        },
    })

    return { eventFeedback, setEventFeedback, setEventFeedbackSync }
}

type UseEventFeedbackAnswerResponse<T extends Question> = {
    answer?: ExtractAnswerFromQuestion<T>
    setAnswer: (answer: ExtractAnswerFromQuestion<T>) => void
}

export const useEventFeedbackAnswer = <T extends Question>(question: T): UseEventFeedbackAnswerResponse<T> => {
    const { eventFeedback, setEventFeedback } = useEventFeedback()

    const answer = eventFeedback.feedbacks.find(a => a.question.title === question.title)?.answer as
        | ExtractAnswerFromQuestion<T>
        | undefined

    const setAnswer = (answer: Answer) => {
        const newAnswer = { question, answer } as Extract<PersonFeedback, { question: { type: T['type'] } }>
        const newAnswers = eventFeedback.feedbacks.filter(a => a.question.title !== question.title)

        setEventFeedback({ ...eventFeedback, feedbacks: [...newAnswers, newAnswer] })
    }

    return { answer, setAnswer }
}
