import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import '@/api/tracking.ts'

import { ErrorPage } from './ErrorPage.tsx'
import { UnauthenticatedProviders } from './contexts/providers.tsx'
import './i18n.ts'
import './index.css'
import './marked.ts'
import { RoutingComponent } from './navigation/RoutingComponent.tsx'
import { Sentry } from './sentry'
import { ThemeProvider } from './theme-provider.tsx'

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
            <UnauthenticatedProviders>
                <BrowserRouter>
                    <ThemeProvider defaultTheme='dark' storageKey='ui-theme'>
                        <RoutingComponent />
                    </ThemeProvider>
                </BrowserRouter>
            </UnauthenticatedProviders>
        </Sentry.ErrorBoundary>
    </React.StrictMode>,
)
