import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AnimatePresence, Variants, motion } from 'framer-motion'
import { ArrowLeftIcon, ArrowRightIcon } from 'lucide-react'
import mixpanel, { Dict } from 'mixpanel-browser'

import ChockLogoBlack from '@/assets/img/chok-logo-black.svg'
import { FreeTextFormStep } from '@/components/FreeTextFormStep'
import { MultiSelectFormStep } from '@/components/MultiSelectFormStep'
import { OptionsSliderFormStep } from '@/components/OptionsSliderFormStep'
import { RadioGroupFormStep } from '@/components/RadioGroupFormStep'
import { SliderFormStep } from '@/components/SliderFormStep'
import { YesNoFormStep } from '@/components/YesNoFormStep'
import { useEventFeedbackAnswer } from '@/hooks/useEventFeedback'
import { useEventId } from '@/hooks/useEventId.ts'
import { useQuestions } from '@/hooks/useQuestions.ts'
import { Question } from '@monorepo/functions/src/types/feedback'

interface Props {
    Logo?: JSX.Element
}

export const UserForm = (props: Props) => {
    const questions = useQuestions()

    if (!questions) return null

    return <UserFormContent questions={questions} {...props} />
}

interface UserFormContentProps extends Props {
    questions: Question[]
}

const arrowButtonVariants: Variants = {
    initial: { scale: 1.1 },
    pressed: { scale: 0.8 },
}

const GoBackButton = motion(ArrowLeftIcon)
const GoForwardButton = motion(ArrowRightIcon)

const trackFormEvent = (eventName: string, properties?: Dict) => {
    mixpanel.track(eventName, { page: 'form', ...properties }, { send_immediately: true })
}

const UserFormContent = ({ questions, Logo }: UserFormContentProps) => {
    const eventId = useEventId()
    const navigate = useNavigate()
    const [step, setStep] = useState(0)
    const currentQuestion = questions[step] as Question
    const isFirstQuestion = step === 0

    const { answer } = useEventFeedbackAnswer(currentQuestion)

    const goToNextStep = () => {
        trackFormEvent('Feedback form next', { question: currentQuestion.title })
        if (step === questions.length - 1) goToSummary()
        else setStep(step + 1)
    }

    const goToPrevStep = () => {
        trackFormEvent('Feedback form back', { question: currentQuestion.title })
        setStep(currentStep => (currentStep > 0 ? step - 1 : 0))
    }
    const goToSummary = () => {
        trackFormEvent('Feedback form finished', { question: currentQuestion.title })
        navigate(`/${eventId}/form-summary`)
    }

    const getStepComponentFromQuestion = (question: Question) => {
        switch (question.type) {
            case 'FREE_TEXT':
                return <FreeTextFormStep question={question} onNextStep={goToNextStep} />
            case 'SLIDER':
                return <SliderFormStep question={question} onNextStep={goToNextStep} />
            case 'OPTIONS_SLIDER':
                return <OptionsSliderFormStep question={question} onNextStep={goToNextStep} />
            case 'YES_NO':
                return <YesNoFormStep question={question} onNextStep={goToNextStep} />
            case 'MULTI_SELECT':
                return <MultiSelectFormStep question={question} onNextStep={goToNextStep} />
            case 'RADIO':
                return <RadioGroupFormStep question={question} onNextStep={goToNextStep} />
            default:
                return null
        }
    }

    return (
        <div className='relative h-full w-full'>
            <div className='relative z-10 flex w-full items-center justify-between'>
                {!isFirstQuestion ? (
                    <GoBackButton
                        variants={arrowButtonVariants}
                        initial='initial'
                        whileTap='pressed'
                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                        className='mr-auto h-6 w-6 cursor-pointer outline-none'
                        onClick={goToPrevStep}
                    />
                ) : (
                    <div className='mr-auto h-6 w-6' />
                )}
                {Logo ? Logo : <img className='ml-auto mr-auto h-12' src={ChockLogoBlack} alt='Logo chök' />}
                {answer ? (
                    <GoForwardButton
                        variants={arrowButtonVariants}
                        initial='initial'
                        whileTap='pressed'
                        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                        className='ml-auto h-6 w-6 cursor-pointer outline-none'
                        onClick={goToNextStep}
                    />
                ) : (
                    <div className='ml-auto h-6 w-6' />
                )}
            </div>
            <AnimatePresence mode='wait'>
                <motion.div
                    key={currentQuestion.title}
                    initial={{ x: 100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -100, opacity: 0 }}
                    transition={{ type: 'spring', stiffness: 500, damping: 30 }}
                    className='-mt-12 flex h-full w-full flex-col items-center justify-center'
                >
                    <form className='relative w-full' onSubmit={ev => ev.preventDefault()}>
                        {getStepComponentFromQuestion(currentQuestion)}
                    </form>
                </motion.div>
            </AnimatePresence>
        </div>
    )
}
