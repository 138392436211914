import { useNavigate } from 'react-router-dom'

import { motion } from 'framer-motion'

const FormOnboarding: React.FC = () => {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate(`./form`)
    }

    return (
        <div className='flex flex-col items-center justify-center'>
            <motion.h1
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className='mb-8 text-center text-3xl font-extrabold tracking-wide'
            >
                Evalúa Matadero
            </motion.h1>

            {/* Animated Icon */}
            <motion.div
                animate={{
                    scale: [1, 1.1, 1],
                    rotate: [0, 5, -5, 0],
                }}
                transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: 'reverse',
                }}
                className='mb-8 rounded-full bg-blue-600 p-5 shadow-lg'
            >
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={2}
                    stroke='currentColor'
                    className='h-14 w-14 text-white'
                    aria-hidden='true'
                >
                    <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z'
                    />
                </svg>
            </motion.div>

            <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }}
                className='mb-8 w-full max-w-sm rounded-lg bg-blue-900 bg-opacity-30 p-5 text-center shadow-lg'
            >
                <h2 className='mb-3 text-xl font-semibold'>Tu opinión cambia Matadero</h2>
                <p>Haz que el próximo evento sea aún más inolvidable</p>
            </motion.div>

            <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.9, duration: 0.5 }}
                onClick={handleClick}
                className='transform rounded-lg bg-blue-600 px-6 py-3 font-bold text-white shadow-lg transition duration-300 ease-in-out hover:scale-105 hover:bg-blue-700'
            >
                Comenzar
            </motion.button>
        </div>
    )
}

export default FormOnboarding
