import { httpsCallable } from 'firebase/functions'

import { functions } from '@/firebase/firebase'
import {
    NewFeedbackQuestionAnsweredRequest,
    NewFeedbackQuestionAnsweredResponse,
} from '@monorepo/functions/src/types/NewFeedbackQuestionAnswered'

import {
    SubscribeToMailerLiteRequest,
    SubscribeToMailerLiteResponse,
} from '../../../backend/functions/src/types/SubscribeToMailerLite'
import {
    SummarizeAllFeedbacksRequest,
    SummarizeAllFeedbacksResponse,
} from '../../../backend/functions/src/types/SummarizeAllFeedbacks'

const newFeedbackQuestionAnsweredCloudFn = httpsCallable<
    NewFeedbackQuestionAnsweredRequest,
    NewFeedbackQuestionAnsweredResponse
>(functions, 'newFeedbackQuestionAnswered')
export const newFeedbackQuestionAnswered = (req: NewFeedbackQuestionAnsweredRequest) => {
    // we do not await on purpose, as it is an async process
    newFeedbackQuestionAnsweredCloudFn(req)
}

const summarizeAllFeedbacksCloudFn = httpsCallable<SummarizeAllFeedbacksRequest, SummarizeAllFeedbacksResponse>(
    functions,
    'summarizeAllFeedbacks',
)
export const summarizeAllFeedbacks = (req: SummarizeAllFeedbacksRequest) =>
    summarizeAllFeedbacksCloudFn(req).then(res => res.data)

export const subscribeToMailerLite = httpsCallable<SubscribeToMailerLiteRequest, SubscribeToMailerLiteResponse>(
    functions,
    'subscribeToMailerLite',
)
