import { FC, useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation, useParams } from 'react-router-dom'

import mixpanel from 'mixpanel-browser'

import BimeLogo from '@/assets/img/bime-logo-black.png'
import ChockLogoBlack from '@/assets/img/chok-logo-black.svg'
import { WithAnonymousUser } from '@/auth/WithAnonymousUser.tsx'
import { FillWaterAnimationProvider } from '@/context/FillWaterAnimationContext.tsx'
import { DocumentsProvider, useDocument } from '@/firebase/hooks/useDocument.tsx'
import { useEventId } from '@/hooks/useEventId.ts'
import DefaultFormOnboarding from '@/pages/FormOnboarding.tsx'
import DefaultFormSummary from '@/pages/FormSummary.tsx'
import { Layout as BaseLayout } from '@/pages/Layout.tsx'
import { UserForm as BaseUserForm } from '@/pages/UserForm.tsx'
import { FormOnboarding as BimeFormOnboarding, FormSummary as BimeFormSummary } from '@/pages/bime'
import { FormOnboarding as ChokFormOnboarding, FormSummary as ChokFormSummary } from '@/pages/chok'
import { Dashboard } from '@/pages/dashboard'
import { LoginPage } from '@/pages/login/LoginPage.tsx'
import { FormOnboarding as OurSpaceFormOnboarding, FormSummary as OurSpaceFormSummary } from '@/pages/our-space'

import { ProtectedPage } from './ProtectedPage.tsx'
import { useEventIdStart } from './useEventIdStart.ts'
import { useQuery } from './useQuery.ts'

const LANDING_URL = 'https://boothbits.com'

const ParamChecker = ({ mandatoryParams }: { mandatoryParams: string[] }) => {
    const params = useParams()

    const areMandatoryParamsPresent = mandatoryParams.every(param => params[param])

    return areMandatoryParamsPresent ? <Outlet /> : <Navigate to='/error' replace />
}

const EventToLayoutClassMap: { [key in string]: string } = {
    chok: 'chok font-chok-courier',
    ourspace: 'our-space font-our-space-font',
    bime: 'bime font-bime-forma',
}

const Layout = () => {
    const eventIdStart = useEventIdStart()
    const eventId = useEventId()
    const source = useQuery('source')
    const location = useLocation()

    useEffect(() => {
        mixpanel.track_pageview({
            eventId,
            page: location.pathname.split('/')[2],
            source,
        })
    }, [location, eventId, source])

    const eventClassNames = EventToLayoutClassMap[eventIdStart] ?? ''

    return (
        <DocumentsProvider>
            <BaseLayout className={eventClassNames} />
        </DocumentsProvider>
    )
}

const EventToOnboardingMap: { [key in string]: FC } = {
    bime: BimeFormOnboarding,
    chok: ChokFormOnboarding,
    ourspace: OurSpaceFormOnboarding,
}

const FormOnboarding = () => {
    const eventIdStart = useEventIdStart()
    const EventFormOnboarding = EventToOnboardingMap[eventIdStart] ?? DefaultFormOnboarding

    const eventId = useEventId()

    const { document: event, isLoading: isEventLoading } = useDocument({
        collectionName: '/events',
        id: eventId,
    })

    if (!isEventLoading && !event) window.location.href = LANDING_URL

    return <EventFormOnboarding />
}

const EventToSummaryMap: { [key in string]: FC } = {
    chok: ChokFormSummary,
    ourspace: OurSpaceFormSummary,
    bime: BimeFormSummary,
}

const FormSummary = () => {
    const eventIdStart = useEventIdStart()
    const EventFormSummary = EventToSummaryMap[eventIdStart] ?? DefaultFormSummary

    return <EventFormSummary />
}

const EventToLogoComponentMap: { [key in string]: JSX.Element } = {
    chok: <img className='ml-auto mr-auto h-12' src={ChockLogoBlack} alt='Logo chök' />,
    bime: <img className='ml-auto mr-auto mt-2 h-12' src={BimeLogo} alt='Logo BIME' />,
}

const UserForm = () => {
    const eventIdStart = useEventIdStart()

    const LogoComponent: JSX.Element = EventToLogoComponentMap[eventIdStart]

    return <BaseUserForm Logo={LogoComponent} />
}

const RedirectToExternal = ({ externalUrl }: { externalUrl: string }) => {
    useEffect(() => {
        window.location.href = externalUrl
    }, [])

    return null
}

export const RoutingComponent = () => (
    <Routes>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/' element={<RedirectToExternal externalUrl={LANDING_URL} />} />
        <Route path='/demo/dashboard' element={<Dashboard isDemo />} />
        <Route element={<ParamChecker mandatoryParams={['eventId']} />}>
            <Route
                path='/:eventId/dashboard'
                element={
                    <ProtectedPage>
                        <Dashboard />
                    </ProtectedPage>
                }
            />
        </Route>
        <Route path='/:eventId' element={<Layout />}>
            <Route path='/:eventId' element={<FormOnboarding />} />
            <Route
                path='/:eventId/form'
                element={
                    <WithAnonymousUser userType='End User'>
                        <FillWaterAnimationProvider>
                            <UserForm />
                        </FillWaterAnimationProvider>
                    </WithAnonymousUser>
                }
            />
            <Route
                path='/:eventId/form-summary'
                element={
                    <WithAnonymousUser userType='End User'>
                        <FormSummary />
                    </WithAnonymousUser>
                }
            />
        </Route>
    </Routes>
)
