import { useEffect } from 'react'

import { HTMLMotionProps, motion } from 'framer-motion'
import mixpanel from 'mixpanel-browser'

import BoothBitsLogo from '@/assets/img/boothbits-logo.svg?react'

export const PoweredBy = (props: HTMLMotionProps<'div'>) => {
    useEffect(() => {
        mixpanel.track_links('#boothbits-landing', 'Link Clicked', { linkId: 'landing-link' })
    }, [])

    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 1, duration: 0.5 }}
            className='mx-[21px] w-full max-w-sm rounded-lg p-5 text-center'
            {...props}
        >
            <a
                id='boothbits-landing'
                className='flex cursor-pointer items-center justify-center text-lg hover:opacity-80'
                href='https://boothbits.com/'
                target='_blank'
            >
                Powered by
                <BoothBitsLogo className='ml-2 w-28 fill-primary' />
            </a>
        </motion.div>
    )
}
