import { motion } from 'framer-motion'

import ChockSymbolBlack from '@/assets/img/chok-symbol-black.svg'
import { PoweredBy } from '@/components/PoweredBy'

export const FormSummary: React.FC = () => {
    return (
        <div className='flex flex-col items-center justify-center px-4 py-4'>
            <h1 className='animate-slide-in mb-8 text-center text-3xl font-extrabold tracking-wide'>
                Gracias por dar tu opinión!
            </h1>

            <motion.div
                animate={{
                    scale: [0.5, 1, 1.2],
                }}
                transition={{ duration: 1 }}
                className='mb-8'
            >
                <img className='h-10' src={ChockSymbolBlack} alt='Logo chök' />
            </motion.div>

            <p className='animate-fade-in-slow mb-6 max-w-lg text-center text-lg leading-relaxed'>
                Enséñale la pantalla al dependiente/a y consigue una mini cookie gratis
            </p>
            <PoweredBy />
            <iframe
                id='lottie-iframe'
                className='fixed bottom-0 mb-6 hidden h-[5rem]'
                src='https://lottie.host/embed/d0464702-4e4c-4fcd-926e-d37741992eb2/six3Bt8pzE.json'
                onLoad={() => document.getElementById('lottie-iframe')!.classList.remove('hidden')}
            />
        </div>
    )
}
