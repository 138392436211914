import { useDocument } from '@/firebase/hooks/useDocument'
import { useEventId } from '@/hooks/useEventId'
import { Event } from '@monorepo/functions/src/types/event'

export const useQuestions = () => {
    const eventId = useEventId()
    const { document: event } = useDocument<Event>({
        collectionName: 'events',
        id: eventId,
    })
    return event?.questions
}
