import { useEffect } from 'react'

import { newFeedbackQuestionAnswered } from '@/api/clients'
import { Textarea } from '@/components/ui/textarea.tsx'
import { useFillWaterAnimation } from '@/context/FillWaterAnimationContext'
import { useEventFeedbackAnswer } from '@/hooks/useEventFeedback'
import { useEventId } from '@/hooks/useEventId'
import { BaseFormStepProps } from '@/types'
import { TextQuestion } from '@monorepo/functions/src/types/feedback'

import { Button } from './ui/button'

export const FreeTextFormStep = ({ question, onNextStep }: BaseFormStepProps<TextQuestion>) => {
    const eventId = useEventId()
    const { answer, setAnswer } = useEventFeedbackAnswer(question)
    const { createDrop, removeWater, resetCanvas, showCanvas, hideCanvas } = useFillWaterAnimation()

    useEffect(() => {
        showCanvas()
        return () => hideCanvas()
    }, [showCanvas, hideCanvas])

    const handleNextClick = async () => {
        newFeedbackQuestionAnswered({
            eventId,
            questionTitle: question.title,
        })
        onNextStep()
        resetCanvas()
    }

    return (
        <div className='space-y-6'>
            <h2 className='text-center text-xl font-bold'>{question.title}</h2>
            <Textarea
                name={question.title}
                className='h-32 border-border bg-background text-foreground placeholder-muted'
                placeholder='Escribe tu respuesta aquí...'
                onChange={event => {
                    const newValue = event.target.value
                    const oldLength = answer?.value?.length || 0
                    const newLength = newValue.length

                    setAnswer({
                        type: 'FREE_TEXT',
                        value: newValue,
                        departments: question.departments,
                        categories: question.categories,
                    })

                    if (newLength > oldLength) {
                        createDrop()
                    } else if (newLength < oldLength) {
                        removeWater(oldLength - newLength)
                    }
                }}
                value={answer?.value ?? ''}
            />
            <div className='flex w-full justify-end'>
                <Button onClick={handleNextClick} disabled={!answer?.value}>
                    Siguiente
                </Button>
            </div>
        </div>
    )
}
