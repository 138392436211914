import { useEventFeedbackAnswer } from '@/hooks/useEventFeedback'
import { BaseFormStepProps } from '@/types'
import { OptionsSliderQuestion, SliderOption } from '@monorepo/functions/src/types/feedback'

import { OptionsSlider } from './ui/options-slider/options-slider'

export const OptionsSliderFormStep = ({ question, onNextStep }: BaseFormStepProps<OptionsSliderQuestion>) => {
    const { answer, setAnswer } = useEventFeedbackAnswer(question)

    const handleValueCommit = (commmittedOption: SliderOption) => {
        setAnswer({
            type: 'OPTIONS_SLIDER',
            value: commmittedOption.value,
            departments: question.departments,
            categories: question.categories,
        })

        onNextStep()
    }

    return (
        <div className='flex flex-col items-center space-y-6'>
            <h2 className='text-center text-xl font-bold'>{question.title}</h2>
            <div className='w-80 space-y-4'>
                <OptionsSlider options={question.options} value={answer?.value} onChangeCommitted={handleValueCommit} />
            </div>
        </div>
    )
}
