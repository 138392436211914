import { motion } from 'framer-motion'

import OurSpaceAstronaut from '@/assets/img/our-space-astronaut.png'
import OurSpaceLogo from '@/assets/img/our-space-logo.png'
import { PoweredBy } from '@/components/PoweredBy'

export const FormSummary: React.FC = () => {
    return (
        <div className='flex flex-col items-center justify-center'>
            <h1 className='animate-slide-in mb-8 text-center text-3xl font-extrabold tracking-wide'>
                Gracias por dar tu opinión!
            </h1>

            <motion.div
                animate={{
                    scale: [0.5, 1, 1.2],
                }}
                transition={{ duration: 1 }}
                className='mb-8'
            >
                <img className='h-10' src={OurSpaceLogo} alt='Our Space Logo' />
            </motion.div>

            <motion.div
                animate={{
                    scale: [1, 1.05, 1],
                    rotate: [0, 2, -2, 0],
                    translateY: [0, -5, 10, 0],
                }}
                transition={{
                    duration: 5,
                    repeat: Infinity,
                    ease: 'easeInOut',
                    repeatType: 'reverse',
                }}
            >
                <img className='m-8 h-28' src={OurSpaceAstronaut} alt='Our Space Logo' />
            </motion.div>

            <p className='animate-fade-in-slow mb-6 max-w-lg text-center text-lg leading-relaxed'>
                Muestra esta pantalla en el stand de merch y obtén un{' '}
                <span className='font-bold'>15% de descuento</span>
            </p>
            <PoweredBy />
        </div>
    )
}
