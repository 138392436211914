import { ReactNode } from 'react'

import { AuthProvider } from '@/hooks/auth'

export const UnauthenticatedProviders = ({ children }: { children: ReactNode }) => {
    return <AuthProvider>{children}</AuthProvider>
}

export const AuthenticatedProviders = ({ children }: { children: ReactNode }) => {
    return <>{children}</>
    // return <StripeProvider>
    //     <ChatBotConfigProvider>
    //         {children}
    //     </ChatBotConfigProvider>
    // </StripeProvider>
}
