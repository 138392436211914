import { useEffect, useState } from 'react'

import { collection, doc, getDoc, getDocs } from 'firebase/firestore'
import { RefreshCw } from 'lucide-react'
import { marked } from 'marked'

import { summarizeAllFeedbacks } from '@/api/clients'
import { Button } from '@/components/ui/button'
import { firestore } from '@/firebase/firebase'
import { useDocument } from '@/firebase/hooks/useDocument'
import { useEventId } from '@/hooks/useEventId'
import { Event } from '@monorepo/functions/src/types/event'
import { Category } from '@monorepo/functions/src/types/feedback'

import { demoSummaries } from './GenerateSampleMessages'

const useShouldShowRegenerateSummary = (eventId: string) => {
    const [shouldShow, setShouldShow] = useState(false)

    useEffect(() => {
        const fetch = async () => {
            const event = (await getDoc(doc(collection(firestore, 'events'), eventId))) as Event
            const totalFeedbacks = (await getDocs(collection(firestore, 'events', eventId, 'feedback'))).size
            const numberOfFeedbacksInSummary = event.summaries?.numberOfFeedbacks
            setShouldShow(
                // If there are no summaries, we should show the regenerate button
                !numberOfFeedbacksInSummary ||
                    // Each 10 new feedbacks we allow to regenerate
                    (numberOfFeedbacksInSummary < 100 && totalFeedbacks - numberOfFeedbacksInSummary > 10) ||
                    // 10% of new feedback we allow to regenerate
                    (numberOfFeedbacksInSummary >= 100 && numberOfFeedbacksInSummary / totalFeedbacks > 0.1),
            )
        }
        fetch()
    }, [])

    return shouldShow
}

interface Props {
    selectedCategory: Category
    isDemo?: boolean
}

export const SummarySection = ({ isDemo, selectedCategory }: Props) => {
    const eventId = useEventId()
    const [isRegenerating, setIsRegenerating] = useState(false)
    const shouldShowRegenerateSummary = useShouldShowRegenerateSummary(eventId)

    const { document: event } = useDocument<Event>({ collectionName: 'events', id: eventId })

    const getCategorySummary = (category: Category) => {
        if (isDemo) return demoSummaries[category]

        switch (category) {
            case 'Problemas':
                return event?.summaries?.problems
            case 'Peticiones':
                return event?.summaries?.requests
            case 'Soluciones':
                return event?.summaries?.solutions
            case 'Fortalezas':
                return event?.summaries?.strengths
            case 'Otros':
                return event?.summaries?.others
        }
    }

    const handleRegenerate = async () => {
        setIsRegenerating(true)
        try {
            await summarizeAllFeedbacks({ eventId })
        } finally {
            setIsRegenerating(false)
        }
    }

    return (
        <div className='mt-8 rounded-lg bg-gray-800 bg-opacity-50 p-4 shadow-xl backdrop-blur-sm'>
            <div className='mb-2 flex justify-between gap-2'>
                <h3 className='mb-2 bg-gradient-to-r from-green-400 to-emerald-600 bg-clip-text text-xl font-semibold text-transparent'>
                    Resumen de los comentarios
                </h3>
                {shouldShowRegenerateSummary && (
                    <Button
                        onClick={handleRegenerate}
                        disabled={isRegenerating}
                        variant='outline'
                        size='sm'
                        className='border-green-400 text-green-400 hover:bg-green-400 hover:text-gray-900'
                    >
                        <RefreshCw className={`mr-2 h-4 w-4 ${isRegenerating ? 'animate-spin' : ''}`} />
                        {isRegenerating ? 'Regenerando...' : 'Regenerar'}
                    </Button>
                )}
            </div>
            <div
                dangerouslySetInnerHTML={{
                    __html: marked(getCategorySummary(selectedCategory) ?? 'No hay resumen todavía'),
                }}
            ></div>
        </div>
    )
}
