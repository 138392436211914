import { useState } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import { HeartHandshake } from 'lucide-react'

import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog'
import { useAuth } from '@/hooks/auth'
import { useAssistantQuestions } from '@/hooks/useAssistantQuestions'

type Rating = 'NEGATIVE' | 'NEUTRAL' | 'POSITIVE'

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const MotionButton = motion.create(Button)

const DEMO_PROMOTER_ID = 'DEMO_PROMOTER_ID'

// TODO this is all being rendered despite the dialog not being opened, maybe we can abstract the dialog content and have the logic there
export const SupportUsDialog = () => {
    const { user } = useAuth()
    const [areAllQuestionsRated, setAreAllQuestionsRated] = useState(false)
    const [open, setOpen] = useState(false)

    const { questions, areQuestionsLoading, isRatingLoading, setPromoterRating } = useAssistantQuestions({
        promoterId: user ? user.uid : DEMO_PROMOTER_ID,
    })

    const currentQuestion = areQuestionsLoading ? null : questions[0]

    const rateAndGoToNextStep = async (rating: Rating) => {
        if (currentQuestion?.id) {
            await setPromoterRating(currentQuestion, rating)
            const wasLastQuestion = questions.length === 1
            if (wasLastQuestion) endSupportUsAndCloseDialog()
        }
    }

    const endSupportUsAndCloseDialog = () => {
        setAreAllQuestionsRated(true)
        wait(1500).then(() => setOpen(false))
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant='ghost' disabled={questions.length === 0}>
                    <p>Colabora</p>
                    <HeartHandshake className='ml-2 h-6 w-6' />
                </Button>
            </DialogTrigger>
            {open && (
                <DialogContent className='min-h-[300px] max-w-[640px] bg-gradient-to-br from-gray-900 to-black text-white'>
                    <DialogHeader>
                        <DialogTitle>Valora nuestras encuestas</DialogTitle>
                        <DialogDescription>
                            Aquí tienes algunas de las preguntas que los asistentes a tus eventos se encontrarán en
                            nuestras encuestas. Colabora con nosotros indicando si las encuentras valiosas o no.
                        </DialogDescription>
                    </DialogHeader>
                    {!areAllQuestionsRated && currentQuestion ? (
                        <AnimatePresence mode='wait'>
                            <motion.div
                                key={currentQuestion.id}
                                initial={{ x: 300, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                exit={{ x: -300, opacity: 0 }}
                                transition={{ type: 'spring', stiffness: 260, damping: 20 }}
                                className='mb-4 mt-6 flex min-h-[50px] items-center justify-center'
                            >
                                <p>{currentQuestion.title}</p>
                            </motion.div>
                        </AnimatePresence>
                    ) : (
                        <motion.p
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ type: 'spring', stiffness: 260, damping: 20 }}
                            className='text-center text-2xl'
                        >
                            Gracias 🤗
                        </motion.p>
                    )}
                    <DialogFooter className='mb-5 ml-auto mr-auto flex-col items-start'>
                        {!areAllQuestionsRated && (
                            <>
                                <MotionButton
                                    variant='ghost'
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={() => rateAndGoToNextStep('NEGATIVE')}
                                    disabled={isRatingLoading}
                                >
                                    <span className='mr-2 text-2xl'>🤨</span>
                                    <span>No es muy útil</span>
                                </MotionButton>
                                <MotionButton
                                    variant='ghost'
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={() => rateAndGoToNextStep('NEUTRAL')}
                                    disabled={isRatingLoading}
                                >
                                    <span className='mr-2 text-2xl'>😐</span>
                                    <span>Me parece bien</span>
                                </MotionButton>
                                <MotionButton
                                    variant='ghost'
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={() => rateAndGoToNextStep('POSITIVE')}
                                    disabled={isRatingLoading}
                                >
                                    <span className='mr-2 text-2xl'>🤩</span>
                                    <span>Aporta mucho valor</span>
                                </MotionButton>
                            </>
                        )}
                    </DialogFooter>
                </DialogContent>
            )}
        </Dialog>
    )
}
