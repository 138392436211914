import { useEventFeedbackAnswer } from '@/hooks/useEventFeedback'
import { BaseFormStepProps } from '@/types'
import { RadioQuestion } from '@monorepo/functions/src/types/feedback'

import { Label } from './ui/label'
import { RadioGroup, RadioGroupItem } from './ui/radio-group'

export const RadioGroupFormStep = ({ question, onNextStep }: BaseFormStepProps<RadioQuestion>) => {
    const { setAnswer, answer } = useEventFeedbackAnswer(question)

    const handleValueChange = (value: string) => {
        setAnswer({
            type: 'RADIO',
            value,
            departments: question.departments,
            categories: question.categories,
        })

        onNextStep()
    }

    return (
        <>
            <h2 className='text-center text-xl font-bold'>{question.title}</h2>
            <div className='mt-6 flex w-fit justify-center justify-self-center'>
                <RadioGroup defaultValue={answer?.value} onValueChange={handleValueChange}>
                    {question.options.map(option => (
                        <div className='flex items-center' key={option.value}>
                            <RadioGroupItem value={option.value} id={option.value} />
                            <Label className='ml-2 text-lg' htmlFor={option.value}>
                                {option.label}
                            </Label>
                        </div>
                    ))}
                </RadioGroup>
            </div>
        </>
    )
}
